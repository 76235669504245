article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }

audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }

audio:not([controls]) { display: none; height: 0; }

[hidden] { display: none; }

html { font-size: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-family: sans-serif; }

button, input, select, textarea { font-family: sans-serif; }

body { margin: 0; }

a:active, a:hover { outline: 0; }

a:focus { outline: thin dotted; }

h1 { font-size: 2em; margin: 0.67em 0; }

h2 { font-size: 1.5em; margin: 0.83em 0; }

h3 { font-size: 1.17em; margin: 1em 0; }

h4 { font-size: 1em; margin: 1.33em 0; }

h5 { font-size: 0.83em; margin: 1.67em 0; }

h6 { font-size: 0.67em; margin: 2.33em 0; }

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

blockquote { margin: 1em 40px; }

dfn { font-style: italic; }

hr { -moz-box-sizing: content-box; box-sizing: content-box; height: 0; }

mark { background: #ff0; color: #000; }

p, pre { margin: 1em 0; }

code, kbd, pre, samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }

pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

q { quotes: none; }

q:after, q:before { content: ''; content: none; }

small { font-size: 80%; }

sub { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; top: -0.5em; }

sub { bottom: -0.25em; }

dl, menu, ol, ul { margin: 1em 0; }

dd { margin: 0 0 0 40px; }

menu, ol, ul { padding: 0 0 0 40px; }

nav ol, nav ul { list-style: none; list-style-image: none; }

img { border: 0; -ms-interpolation-mode: bicubic; }

svg:not(:root) { overflow: hidden; }

figure, form { margin: 0; }

fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

legend { border: 0; padding: 0; white-space: normal; *margin-left: -7px; }

button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }

button, input { line-height: normal; }

button, select { text-transform: none; }

button, html input[type="button"] { -webkit-appearance: button; cursor: pointer; *overflow: visible; }

input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; *overflow: visible; }

button[disabled], html input[disabled] { cursor: default; }

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }

input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

textarea { overflow: auto; vertical-align: top; }

table { border-collapse: collapse; border-spacing: 0; }

.centralizar { width: 1170px; }

.acessados-link { box-sizing: border-box; behavior: url("../js/boxsizing.htc"); }

.veja-mais { position: relative; float: left; width: 33.33333%; min-height: 1px; padding-right: 0; padding-left: 0; }

.veja-mais + .veja-mais { position: relative; float: left; width: 25%; min-height: 1px; padding-right: 0; padding-left: 0; }

.veja-mais + .veja-mais + .veja-mais { position: relative; float: left; width: 41.66667%; min-height: 1px; padding-right: 0; padding-left: 0; }

